import React, { FC, useContext } from 'react';
import RelatedArticlesItem from 'gatsby-theme-husky/src/components/RelatedArticles/RelatedArticlesItem/RelatedArticlesItem';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles/RelatedArticles';
import Carousel from 'gatsby-theme-husky/src/common/Carousel/Carousel';
import ScreenRecognitionContext from 'gatsby-theme-husky/src/utils/context/screenRecognitionContext';
import { RelatedArticlesProps } from 'gatsby-theme-husky/src/components/RelatedArticles/models';
import { CAROUSEL_DEFAULT_SETTINGS } from 'gatsby-theme-husky/src/utils/constants';
import './RelatedArticlesOverride.scss';

const RelatedArticlesOverride: FC<RelatedArticlesProps> = ({
  relatedArticles,
  tipsAndTricks,
  relatedArticlesTitle,
}) => {
  const { isMobile } = useContext(ScreenRecognitionContext);

  const carouselSettings = {
    ...CAROUSEL_DEFAULT_SETTINGS,
    arrows: false,
  };

  return isMobile ? (
    <div className="related-articles">
      <h2 className="related-articles__title">{relatedArticlesTitle}</h2>
      <div className="related-articles__cards">
        <Carousel settings={carouselSettings}>
          {relatedArticles.map(
            ({ title, teaser, articleBannerImageAltText, articleBannerImage, url }) => (
              <RelatedArticlesItem
                key={title}
                {...{
                  title,
                  description: teaser,
                  imageAlt: articleBannerImageAltText,
                  image: articleBannerImage,
                  url,
                  relatedArticlesButton:
                    tipsAndTricks.relatedArticlesButtonNodes[0].relatedArticlesButton,
                }}
              />
            )
          )}
        </Carousel>
      </div>
    </div>
  ) : (
    <RelatedArticles {...{ relatedArticles, tipsAndTricks, relatedArticlesTitle }} />
  );
};

export default RelatedArticlesOverride;
